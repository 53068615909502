import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'
import { Link } from "gatsby"

import logoImg from "./mtg-onl-tokens-logo.svg"
import logoImSm from "./mtg-onl-tokens-logo-sm.svg"
import * as styles from './logo.module.scss';

const Logo = ({ className }) => (
  <Link to="/" className={classnames(styles.logo, className)}>
    <picture>
      <source srcSet={logoImg} media="(min-width: 768px)" />
      <img src={logoImSm} alt="mtg.onl tokens logo" />
    </picture>
  </Link>
)

Logo.propTypes = {
  className: PropTypes.string
}

export default Logo
