import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'
import Img from "gatsby-image"
import { Link } from "gatsby"

import * as styles from './featuredPost.module.scss';
import * as postStyles from '../posts.module.scss';
import * as helperStyles from '../../helper/helper.module.scss'

import { imgSrcType } from '../../../types'

const FeaturedPost = ({ title, imgSrc, theme, route }) => (
  <div className={styles.wrapper}>
    <span className={helperStyles.helperText}>Featured Post</span>
    <Link to={route} className={classnames(styles.content, postStyles[theme], postStyles.link)}>
      <h4 className={styles.h4}>{title}</h4>
      {imgSrc && <Img fluid={imgSrc.childImageSharp.fluid} alt={title} className={styles.img} /> }
      <span className={styles.linkText}>View full post &rarr;</span>
    </Link>
  </div>
)

FeaturedPost.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: imgSrcType,
  theme: PropTypes.string,
  route: PropTypes.string.isRequired
}

export default FeaturedPost
