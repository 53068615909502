const SITE_URL = "https://www.mtg.onl";

const getKeywords = (keywords) => {
  const all = keywords.concat(["tokens", "mtg", "token", "Magic: the Gathering", "MTG tokens", "magic"])
  return JSON.stringify(all.join(`, `))
};

const getDefaultPostData = ({ route, title, keywords, shortDescription, date, img, modifiedDate }) => {
  const dateTime = new Date(date).toISOString();
  const modifiedDateTime = new Date(modifiedDate).toISOString();
  return (
  `{
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "name": "${title}",
    "description": "${shortDescription}",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": "${SITE_URL}${route}"
    },
    "url": "${SITE_URL}${route}",
    "headline": "${title}",
    "datePublished": "${dateTime}",
    "dateModified": "${modifiedDateTime}",
    "image": {
      "@type": "ImageObject",
      "url": "${SITE_URL}${img}",
      "width": 1000,
      "height": 1000,
      "caption": "${title}"
    },
    "author": {
      "@type": "Person",
      "name": "Adam Foster",
      "description": "I have been playing Magic: the Gathering for over 15 years and collecting MTG token cards from when I first saw the player reward tokens in Onslaught.  I mostly play token themed decks, B/W Tokens in Modern, G/W Rhys the Redeemed in EDH and what ever token deck I can brew in Standard and MTG Arena. I live and work in Sydney, Australia.",
      "sameAs": [
        "https://twitter.com/codefor"
      ],
      "image": {
        "@type": "ImageObject",
        "url": "${SITE_URL}/static/adam-foster_1080x1080.jpg",
        "width": 1080,
        "height": 1080
      }
    },
    "publisher": {
      "@type": "Organization",
      "name": "MTG.Onl Tokens",
      "logo": {
        "@type": "ImageObject",
        "url": "${SITE_URL}/favicons/icon-512x512.png",
        "width": 512,
        "height": 512
      },
      "url": "${SITE_URL}"
    },
    "inLanguage": "en",
    "keywords": [${getKeywords(keywords)}],
    "genre": "Magic: the Gathering"
  }`
  );
}

const getDefaultFaqData = ({ question, answer, route, date}) => {
  const dateTime = new Date(date).toISOString();
  return (
    `{
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "url": "${SITE_URL}${route}",
      "mainEntity": [{
        "@type": "Question",
        "name": "${question}",
        "dateCreated": "${dateTime}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${answer}",
          "dateCreated": "${dateTime}"
        }
      }]
    }`
  )
};

const getBreadCrumData = ({ pages }) => {
  return (
    `{
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [          {
        "@type": "ListItem",
        "position": 1,
        "name": "MTG.onl Tokens",
        "item": "https://www.mtg.onl/"
      },
      ${pages.map((page, index) => {
        return (`
          {
            "@type": "ListItem",
            "position": ${index + 2},
            "name": "${page.text}",
            "item": "https://www.mtg.onl${page.to}"
          }
        `)
      })}]
    }`
  )
};

export {
  getDefaultPostData,
  getDefaultFaqData,
  getKeywords,
  getBreadCrumData
};
