import React from "react"
import PropTypes from "prop-types"

import { Header, Sidebar, Footer, AllFeaturedPosts, Helper, Brave } from "../index"

import "../../styles/main.scss";
import * as styles from './layout.module.scss';
import * as helperStyles from '../helper/helper.module.scss'

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      adBlockDetected: false
    }
  }

  componentDidMount() {
    this.detectAdBlocker();
  }

  detectAdBlocker() {
    if(!document) return;

    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.id = 'ads';
    script.type = 'text/javascript';
    script.src = '/adsbygoogle.js';
    script.onload = () => this.setState({ adBlockDetected: false});
    script.onerror = () => this.setState({ adBlockDetected: true });
    head.appendChild(script);
}

  handleScrollToSidebar = () => {
    if(this.sidebarRef){
      window.scrollTo(0, this.sidebarRef.offsetTop);
    }
  }

  handleScrollToTop = () => {
    window.scrollTo(0, 0);
  }

  render() {
    const { children, location: { pathname } } = this.props;
    const { adBlockDetected } = this.state;

    return (
      <>
        <Header onMenuClick={this.handleScrollToSidebar} blocked={adBlockDetected} />
        <div className={styles.container}>
          <Sidebar sideBarRef={(el) => this.sidebarRef = el} onTopClick={this.handleScrollToTop} />
          <main className={styles.main}>
            <div className={styles.children}>
              {children}
            </div>
            <aside className={styles.help}>
              <AllFeaturedPosts pathname={pathname} />
              <Brave />
              <Helper
                slot="2380378575"
                className={helperStyles.asideHelper}
                fallbackClassName={helperStyles.asidePaypal}
                blocked={adBlockDetected}
                brave={true}
              />
            </aside>
          </main>
        </div>
        <Footer/>
      </>
    )
  }

}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
}

export default Layout
