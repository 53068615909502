import PropTypes from "prop-types"
import React from "react"
import { Twitter, Facebook, Reddit } from 'react-social-sharing'
import { Location } from '@reach/router'

import * as styles from './socialLinks.module.scss';

const SocialLinks = ({ title, location: { href } }) => (
  <div className={styles.links}>
    <Reddit solidcircle big link={href}/>
    <Twitter solidcircle big message={title} link={href}/>
    <Facebook solidcircle big link={href}/>
  </div>
)

SocialLinks.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.shape({
    href: PropTypes.string.isRequired
  })
}

export default props => (
  <Location>
    {locationProps => <SocialLinks {...locationProps} {...props} />}
  </Location>
);
