import React from "react"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Set, Promos } from '../../index';
import * as styles from './setTokens.module.scss';
import * as tokenGroupStyles from '../tokensGroup.module.scss'
import { getSetBreadcrum } from '../../../utils/breadcrums'
import { baseSetType, setTokenType } from '../../../types'

class SetTokens extends React.PureComponent {
  renderForNoToken({childImageSharp, number}) {
    return (
      <li key={number} className={tokenGroupStyles.token}>
        <Img fluid={childImageSharp.fluid} className={tokenGroupStyles.img} />
        <p>
          {number}
        </p>
      </li>
    )
  }
  render() {
    const { 
      name, 
      id: setId, 
      name: setName, 
      releaseDate, 
      tokens
    } = this.props.set;
    return (
      <article className={tokenGroupStyles.wrapper}>
        <header className={tokenGroupStyles.header}>
          <h3>
            <Set id={setId} className={styles.set} />
            {name}
            <span className={styles.secondary}>({tokens.length})</span>
          </h3>
          <div className={styles.date}>{releaseDate}</div>
        </header>
        <ul className={tokenGroupStyles.container}>
          {
            tokens.map((token, index) => {
              const {
                id,
                number,
                foil,
                promo,
                imgSrc: {
                  childImageSharp
                }
              } = token;
              if(!token.token) {
                return this.renderForNoToken({childImageSharp, number});
              }
              const {
                name,
                id: tokenId
              } = token.token;
              return (
                <li key={id} className={tokenGroupStyles.token}>
                  <Link
                    to={`/token-list/${tokenId}/`}
                    className={tokenGroupStyles.link}
                    state={{
                      breadcrum: getSetBreadcrum({ name: setName, id: setId }),
                      featuredTokenId: id
                    }}
                  >
                    <Img fluid={childImageSharp.fluid} className={tokenGroupStyles.img} />
                    <p>
                      {name}
                      <span className={tokenGroupStyles.secondary}>({number})</span>
                      <Promos foil={foil} promo={promo} center={true} />
                    </p>
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </article>
    )
  }
}

SetTokens.propTypes = {
  set: PropTypes.shape({
    ...baseSetType,
    tokens: PropTypes.arrayOf(PropTypes.shape({
      ...setTokenType,
    })).isRequired
  })
}

export default SetTokens
