import React from "react";
import * as PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { FilterList } from '../../index';
import * as sidebarStyles from '../sidebar.module.scss'
import { baseTokenType } from '../../../types'

class TokenTypeList extends React.PureComponent {
  render() {
    const { allTypesJson } = this.props.data;
    return (
      <FilterList
        className={sidebarStyles.section}
        title="A-Z Token List"
        pageUrl="/token-list/"
        searchText="token type"
        typeList={allTypesJson.edges}
      />
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavAllTypesQuery {
        allTypesJson(sort: {fields: [name], order: ASC}) {
          edges {
            node {
              id
              name
              color
              power
              toughness
            }
          }
        }
      }
    `}
    render={data => <TokenTypeList data={data} {...props} />}
  />
)

TokenTypeList.propTypes = {
  data: PropTypes.shape({
    allTypesJson: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseTokenType,
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}
