import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'

import { Color } from '../../index'
import * as styles from './colors.module.scss'
import { colorTypes } from '../../../types'

const Colors = ({ color, size, className }) => {
  if(!color) {
    return null;
  }
  return (
    <>
      {
        color.map(color => <Color
            key={color}
            name={color}
            className={classnames(styles.color, className, {
              [styles[`${size}Size`]]: size,
            })}
          />)
      }
    </>
  )
}

Colors.defaultProps = {
  size: `md`,
}

Colors.propTypes = {
  color: PropTypes.arrayOf(PropTypes.oneOf(colorTypes)),
  className: PropTypes.string,
  size: PropTypes.string,
}

export default Colors
