const getSetBreadcrum = ({ id, name, active }) => {
  return [
    {
      text: 'Tokens by set',
      to: '/tokens/',
    },
    {
      text: name,
      set: id,
      to: `/tokens/${id}/`,
      active
    }
  ];
}

export {
  getSetBreadcrum
};