import React from "react"
import * as PropTypes from "prop-types"

import { Set } from '../../index'
import * as styles from './sets.module.scss'
import { baseSetType } from '../../../types'

const Sets = ({ set }) => {
  if(!set) {
    return (
      <p><strong>Set:</strong><span className={styles.proxy}>PROXY</span></p>
    )
  }
  const { id, name } = set;
  return (
    <p>
      <strong>Set: </strong>
      <Set id={id} name={name} />
      {name}
    </p>
  )
}

Sets.propTypes = {
  set: PropTypes.shape({
    ...baseSetType,
  }),
}

export default Sets
