const insertIcons = (source) => {
		var str = source.replace(/\n/g, '<br/>');

		str = str.replace(/\{0}/g, '<i class="ms ms-cost ms-0"></i>');
		str = str.replace(/\{1}/g, '<i class="ms ms-cost ms-1"></i>');
		str = str.replace(/\{2}/g, '<i class="ms ms-cost ms-2"></i>');
		str = str.replace(/\{3}/g, '<i class="ms ms-cost ms-3"></i>');
		str = str.replace(/\{T}/g, '<i class="ms ms-tap"></i>');
		str = str.replace(/Tap:/g, '<i class="ms ms-tap"></i>:');
		str = str.replace(/\{G}/g, '<i class="ms ms-cost ms-g"></i>');
		str = str.replace(/\{B}/g, '<i class="ms ms-cost ms-b"></i>');
		str = str.replace(/\{R}/g, '<i class="ms ms-cost ms-r"></i>');
		str = str.replace(/\{W}/g, '<i class="ms ms-cost ms-w"></i>');
		str = str.replace(/\{U}/g, '<i class="ms ms-cost ms-u"></i>');
    str = str.replace(/\{OX}/g, '<i class="ms ms-cost ms-ox"></i>');
    
		return {
      __html: str
    };
};

export default insertIcons;