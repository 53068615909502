import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, meta, keywords, title, lang, pageUrl, noindex, imgSrc }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  let metaDescription = description || site.siteMetadata.description;
  metaDescription = `${metaDescription.replace(/<[^>]*>?/gm, '').substring(0, 161)}...`;

  const allKeywords = keywords.concat(['tokens', 'mtg', 'token', 'token list', 'token database', 'database',  'Magic: the Gathering', 'Creature tokens', 'emblem', 'MTG tokens', 'creature token', 'magic']);

  let socialImgSrc = imgSrc ? imgSrc : '/favicons/icon-512x512.png';
  socialImgSrc = `https://www.mtg.onl${socialImgSrc}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      {
        ...(pageUrl ? {
          link: [{
            rel: 'canonical',
            href: `https://www.mtg.onl${pageUrl}`,
          }]
        } : {})
      }
      meta={[
        {
          ...(noindex ? {
            name: `robots`,
            content: 'noindex',
          } : {})
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:image`,
          content: socialImgSrc,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: socialImgSrc,
        },
      ]
        .concat(
          allKeywords.length > 0
            ? {
                name: `keywords`,
                content: allKeywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
