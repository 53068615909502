import React from "react"
import { Link } from "gatsby"
import * as classnames from 'classnames'
import Helmet from "react-helmet"

import { Set } from '../index'
import * as styles from './breadcrum.module.scss'
import { breadcrumType } from '../../types'
import { getBreadCrumData } from '../../utils/structuredData';


class Breadcrum extends React.PureComponent {
  render() {
    const { pages } = this.props;
    return (
      <>
        <nav className={styles.breadcrum}>
          <ol>
            <li>
              <Link to="/" className={styles.home}>
                <span className={styles.homeIcon}><span>MTG.onl Tokens</span></span>
              </Link>
              <span className={styles.divider}>&rsaquo;</span>
            </li>
            {
              pages.map((page, index) => {
                const { to, text, set, active } = page;
                return (
                  <li key={index} className={classnames({
                    [styles.active]: active
                  })}>
                    <Link to={to}>
                      {set && <Set id={set} size="sm" />}
                      {text}
                    </Link>
                    { !active &&
                      <span className={styles.divider}>&rsaquo;</span>
                    }
                  </li>
                )
              })
            }
          </ol>
        </nav>
        {
          pages.length > 1 && <Helmet>
            <script type="application/ld+json">{getBreadCrumData({pages: pages})}</script>
          </Helmet>
        }
      </>
    );
  }
}

Breadcrum.propTypes = {
  pages: breadcrumType
};

export default Breadcrum;
