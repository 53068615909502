import PropTypes from "prop-types"
import React from "react"
import classnames from 'classnames';

import * as styles from './set.module.scss'
import { baseSetType } from '../../../types'

const Set = ({ name, id, className, size }) => {
  const setclass = `ss-${id.toLowerCase()}`;
  return (
    <i title={name} className={classnames(setclass, 'ss', styles.set, className, {
      [styles[`${size}Size`]]: size,
    })}></i>
  )
}

Set.propTypes = {
  ...baseSetType,
  className: PropTypes.string,
  size: PropTypes.string
}

Set.defaultProps = {
  size: 'md'
}

export default Set
