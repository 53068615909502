import React from "react";
import * as PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { FeaturedPost, SetPost, TokenFinderPost } from '../../index'
import { featuredPostType, baseSetType } from '../../../types'
import * as styles from './allFeaturedPosts.module.scss'

const POST_TYPES = {
  'TOKEN_FINDER': 'TOKEN_FINDER',
  'LATEST_SET': 'LATEST_SET',
  'POST': 'POST'
}

class AllFeaturedPosts extends React.PureComponent {

  getFeaturedPostsForPath () {
    const { pathname, data: { latestSetPost, allPosts }} = this.props;
    const postItems = [];
    
    if(!pathname.includes('/token-finder')) {
      postItems.push({
        type: POST_TYPES.TOKEN_FINDER,
        route: '/token-finder/'
      })
    }

    const { id: setId } = latestSetPost.edges[0].node;
    if(pathname !== '/' && pathname !== '/tokens/' && !pathname.includes(`/tokens/${setId}`)) {
      postItems.push({
        type: POST_TYPES.LATEST_SET,
        route: `/tokens/${setId}/`
      })
    }

    allPosts.edges.forEach((post, index) => {
      const postRoute = post.node.route;
      if(pathname !== postRoute && pathname !== '/posts/') {
        postItems.push({
          type: POST_TYPES.POST,
          route: postRoute,
          id: index
        })
      }
    })

    return this.shuffle(postItems).slice(0,2)
  }

  shuffle = (array) => {
    const { pathname } = this.props;
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    // // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      const randomNum = pathname.length > 3 ? pathname.charCodeAt(pathname.length - 2) : currentIndex;
      const randomNum2 = pathname.length > 4 ? pathname.charCodeAt(pathname.length - 3) : 109 - currentIndex;
      randomIndex = Math.abs(((randomNum * currentIndex) + (randomNum2 % currentIndex)) % array.length);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  };

  render() {
    const { 
      latestSetPost,
      allPosts
    } = this.props.data;
    const featuredPostItems = this.getFeaturedPostsForPath();
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {
            featuredPostItems.map((post, index) => {
              switch (post.type) {
                case POST_TYPES.TOKEN_FINDER:
                  return <TokenFinderPost key={index} />;
                case POST_TYPES.POST:
                  return <FeaturedPost key={index} {...allPosts.edges[post.id].node} />;
                case POST_TYPES.LATEST_SET:
                  return <SetPost  key={index} {...latestSetPost.edges[0].node} />;
                default:
                  return null;
              }
            })
          }
        </div>
      </div>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query AllFeaturedPostsQuery {
        allPosts: allPostsJson(sort: {fields: [date], order: DESC }) {
          edges {
            node {
              title
              route
              imgSrc {
                childImageSharp {
                  fluid(maxWidth: 270) {
                    src
                    srcSet
                    aspectRatio
                    sizes
                  }
                }
              }
              theme
            }
          }
        }
        latestSetPost: allSetsJson(
          sort: {fields: [releaseDate], order: DESC}
          limit: 1
        ) {
          edges {
            node {
              id
              name
              releaseDate(formatString: "MMMM Do, YYYY")
              tokens {
                id
                }
              }
            }
          }
      }
    `}
    render={data => <AllFeaturedPosts data={data} {...props} />}
  />
)

AllFeaturedPosts.propTypes = {
  pathname: PropTypes.string.isRequired,
  data: PropTypes.shape({
    allPosts: featuredPostType,
    latestSetPost: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseSetType,
          tokens: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string
          }))
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}
