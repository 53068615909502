import React from "react"

import * as styles from './howItWorks.module.scss';
import tokenList from "./enter-token-list.png"
import browseList from "./browse-token-list.png"
import tokenProxy from './print-token-proxies.jpg'

const HowItWorks = () => (
  <aside className={styles.container}>
    <h4>How it works:</h4>
    <p>Enter your deck cardnames, one on each line:</p>
    <img src={tokenList} alt="Enter your deck list" width="153" height="62" />
    <p>Browse the Tokens cards your deck can create:</p>
    <img src={browseList} alt="Browse the tokens needed for your deck" width="250" />
    <p>Print your token proxies.<br />
    (You'll get 9 proxies on a sheet of landscape A4)</p>
    <img src={tokenProxy} alt="Print your tokens" height="75" width="250" />
    <p>Or, copy to clipboard to buy online.</p>
  </aside>
)

export default HowItWorks
