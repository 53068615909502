import React from "react";
import * as PropTypes from "prop-types"
import * as classnames from "classnames"
import Img from "gatsby-image"
import { Link } from "gatsby"

import * as styles from './finderResults.module.scss'
import './print.scss';
import * as finderStyles from '../finderField/finderField.module.scss'
import * as tokenGroupStyles from '../../token/tokensGroup.module.scss'
import { getTokenBySources } from '../../../utils/tokens'
import { Colors, Sources } from '../../index'
import { imgSrcType, baseTokenType, baseSetType } from '../../../types'

class FinderResults extends React.PureComponent {
  constructor(props) {
    super(props);
    const { searchData, tokens } = props;
    const { filteredTokens , unMatchedSources } = getTokenBySources(tokens, searchData);
    this.state = {
      filteredTokens,
      unMatchedSources,
      copied: false
    };
  }

  breadcrum = {
    text: 'Tokens finder',
    to: '/token-finder/'
  }

  handleCopyClick() {
    const { filteredTokens } = this.state;
    let copyText = '';
    filteredTokens.forEach((token)=>{
      const featuredToken = token.node.tokens[0];
      copyText += `${token.node.name} token (${featuredToken.set.id})\n`
    })
    this.copySheetField.value = copyText;
    this.copySheetField.select();
    document.execCommand('copy');
    this.setState({copied: true});
    setTimeout(() => {
      this.setState({copied: false});
      this.copySheetField.value = "";
    }, 2000);
  }

  handlePrintClick() {
    document.body.classList.add('print-mode');
    window.print()
  }

  renderButton() {
    const { handleBackClick } = this.props;
    const { copied } = this.state;
    return (
      <>
        <button
          onClick={() => handleBackClick()}
          className={classnames(finderStyles.btn, styles.back)}
        >
          <span className={finderStyles.icon}>&larr;</span> Back
        </button>
        <button
          onClick={() => this.handlePrintClick()}
          className={finderStyles.btn}
        >
          <span className={classnames(finderStyles.icon, styles.print)} /> Print
        </button>
        <button
          onClick={() => this.handleCopyClick()}
          className={finderStyles.btn}
        >
          { 
            copied
              ? (<><span className={classnames(finderStyles.icon, styles.tick)} dangerouslySetInnerHTML={{__html: `&check;`}} />Copied to clipboard</>)
              : (<><span className={classnames(finderStyles.icon, styles.copy)} />Copy to clipboard</>)
          }          
        </button>
        <textarea className={styles.copySheet} ref={el => this.copySheetField = el}></textarea>
      </>
    )
  }

  render() {
    const { searchData } = this.props;
    if(Object.keys(searchData).length === 0) {
      return null;
    }
    const { filteredTokens, unMatchedSources } = this.state;
    return (
      <>
        <header className={tokenGroupStyles.header}>
          <h4>Tokens needed for your deck:</h4>
        </header>
        <ul className={tokenGroupStyles.container}>
          {
            filteredTokens.map((token)=>{
              const {
                id, name, color, tokens,
                sources, power, toughness
              } = token.node;
              const featuredToken = tokens[0];
              const fluid = {
                ...featuredToken.imgSrc.childImageSharp.fluid,
                maxWidth: 175
              }
              return (
                <li key={id} className={classnames(tokenGroupStyles.token, 'print-token')}>
                  <Link
                    to={`/token-list/${id}/`}
                    className={tokenGroupStyles.link}
                    state={{breadcrum: [this.breadcrum]}}
                  >
                    <Img fluid={fluid} className={classnames(tokenGroupStyles.img, 'print-img')} />
                    <p>
                      <Colors size="xs" color={color} />
                      {name}
                      {power && <span className={styles.pt}>{` (${power}/${toughness})`}</span>}
                    </p>
                  </Link>
                  <Sources sources={sources} />
                </li>
              );
            })
          }
        </ul>
        <footer className={styles.footer}>
          <h4>These cards in your deck don't create tokens:</h4>
          <Sources sources={unMatchedSources} hideLabel={true} limit={20} />
          {this.renderButton()}
        </footer>
      </>
    );
  }
}

FinderResults.propTypes = {
  searchData: PropTypes.object.isRequired,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      ...baseTokenType,
      sources: PropTypes.arrayOf(PropTypes.string),
      tokens: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        imgSrc: imgSrcType,
        set: PropTypes.shape({
          ...baseSetType,
        }),
      }))
    }).isRequired
  })),
  handleBackClick: PropTypes.func.isRequired
};

export default FinderResults;
