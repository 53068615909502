import React from "react"
import * as PropTypes from "prop-types"

import * as styles from './sources.module.scss';

const cardUrl = (cardName) => `https://scryfall.com/search?q=${encodeURI(cardName)}`;

class Sources extends React.PureComponent {
  state = {
    open: false
  };
  toggleShowMoreClick = () => {
    this.setState(prevState => ({
      open: !prevState.open
    }));
  }
  render() {
    const { sources, hideLabel, limit } = this.props;
    const { open } = this.state;
    if(!sources) {
      return null;
    }
    const singular = sources.length === 1;
    const label = singular ? 'Source: ' : 'Sources: ';
    const sourceLimit = open ? sources.length : limit;
    const showMore = sources.length > limit;
    const showMoreLabel = open ? 'Hide sources' : `Show all ${sources.length} sources`;
    return (
      <p className={styles.wrapper}>
        { !hideLabel && <strong>{label}</strong> }
        {
          sources.slice(0, sourceLimit).map((source, index) => {
            const showSeperator = (index !== 0);
            return (
              <React.Fragment key={index}>
                {
                  showSeperator && `, `
                }
                <a title={`View ${source} on Scryfall`} target="_blank" href={cardUrl(source)} rel="noopener noreferrer">
                  {source}
                </a>
              </React.Fragment>
            )
          })
        }
        {
          showMore && (
            <>
              {open ? '. ': `... `}
              <button className={styles.showMore} onClick={this.toggleShowMoreClick}>
                {showMoreLabel}
              </button>
            </>
          )
        }
      </p>
    )
  }
}

Sources.propTypes = {
  limit: PropTypes.number,
  hideLabel: PropTypes.bool,
  sources: PropTypes.arrayOf(PropTypes.string)
}

Sources.defaultProps = {
  limit: 10,
  hideLabel: false
}

export default Sources
