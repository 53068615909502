import * as PropTypes from "prop-types"

export const colorTypes = [
  'W',
  'G',
  'B',
  'U',
  'R'
];

export const baseTokenType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  power: PropTypes.string,
  toughness: PropTypes.string,
  color: PropTypes.arrayOf(PropTypes.oneOf(colorTypes)),
}

export const fluidImgType = PropTypes.shape({
  base64: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  aspectRatio: PropTypes.number
  // sizes
})

export const imgSrcType = PropTypes.shape({
  childImageSharp: PropTypes.shape({
    fluid: fluidImgType
  })
});

export const baseSetType = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  releaseDate: PropTypes.string
}

export const breadcrumType = PropTypes.arrayOf(PropTypes.shape({
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  set: PropTypes.string,
  active: PropTypes.bool
}))

export const artworkTokenType = {
  id: PropTypes.string.isRequired,
  promo: PropTypes.string,
  foil: PropTypes.bool,
  artist: PropTypes.string,
  number: PropTypes.string,
  set: PropTypes.shape({
    ...baseSetType,
  }),
  imgSrc: imgSrcType
}

export const tokenTypeType = {
  ...baseTokenType,
  text: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.string),
  subTypes: PropTypes.arrayOf(PropTypes.string),
  sources: PropTypes.arrayOf(PropTypes.string),
  proxy: PropTypes.bool,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    ...artworkTokenType
  }))
}

export const pageLinkType = {
  prev: PropTypes.string,
  next: PropTypes.string,
  prevName: PropTypes.string,
  nextName: PropTypes.string,
  limit: PropTypes.number
}

export const setTokenType = {
  ...artworkTokenType,
  token: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
  })
}

export const postsType = PropTypes.shape({
  node: PropTypes.shape({
    title: PropTypes.string.isRequired,
    author: PropTypes.string,
    date: PropTypes.string,
    formattedDate: PropTypes.string,
    route: PropTypes.string.isRequired,
    shortDescription: PropTypes.string,
    imgSrc: imgSrcType,
    socialImgSrc: imgSrcType,
    theme: PropTypes.string,
    contentImages: PropTypes.arrayOf(imgSrcType)
  }).isRequired
})

export const faqsType = PropTypes.shape({
  question: PropTypes.string,
  answer: PropTypes.string,
  route: PropTypes.string.isRequired
})

export const allPostsType = PropTypes.shape({
  edges: PropTypes.arrayOf(postsType).isRequired,
})

export const featuredPostType = PropTypes.shape({
  edges: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      title: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
      theme: PropTypes.string,
      imgSrc: imgSrcType
    }).isRequired,
  })).isRequired
});