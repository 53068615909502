import React from "react"
import * as classnames from 'classnames'

import BraveBanner from './browse-with-brave.png'
import * as styles from './helper.module.scss';

const Brave = () => (
  <div className={classnames(styles.helper, styles.brave)}>
    <span className={styles.helperText}>Browse with Brave</span>
    <div className={styles.content}>
      <a href="https://brave.com/mtg368">
        <img src={BraveBanner} alt="Help support us by browsing with Brave" />
      </a>
    </div>
  </div>
)

export default Brave
