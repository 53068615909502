import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import * as PropTypes from "prop-types"

import { Set } from '../../index';

import { baseSetType } from '../../../types'

const LastUpdated = ({ data }) => {
  const { releaseDate, name, id } = data.allSetsJson.edges[0].node;
  return (
    <p>Last updated for the release of <Link to={`/tokens/${id}/`}>
        <Set id={id} size="sm" />{name}
      </Link> set on <strong>{releaseDate}</strong>.
    </p>
  )
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query {
        allSetsJson(
          sort: {fields: [releaseDate], order: DESC}
          limit: 1
        ) {
          edges {
            node {
              id
              name
              releaseDate(formatString: "MMMM Do, YYYY")
            }
          }
        }
      }
    `}
    render={data => <LastUpdated data={data} {...props} />}
  />
)

LastUpdated.propTypes = {
  data: PropTypes.shape({
    allSetsJson: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          ...baseSetType,
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}
