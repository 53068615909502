import React from "react";
import * as PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { FilterList } from '../../index';
import * as sidebarStyles from '../sidebar.module.scss'

class SetList extends React.PureComponent {
  render() {
    const { allSetsJson } = this.props.data;
    return (
      <FilterList
        className={sidebarStyles.section}
        title="Tokens by set"
        pageUrl="/tokens/"
        searchText="set"
        setList={allSetsJson.edges}
      />
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavAllSetsQuery {
        allSetsJson(
          sort: {fields: [releaseDate], order: DESC}
        ) {
          edges {
            node {
              id
              name
              tokens {
                id
              }
            }
          }
        }
      }
    `}
    render={data => <SetList data={data} {...props} />}
  />
)

SetList.propTypes = {
  data: PropTypes.shape({
    allSetsJson: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tokens: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string.isRequired,
          })).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}

