export const getFirstTokenById = (tokens, id) => {
  const matches = tokens.filter(token => token.id === id);
  return matches[0]
}

export const getTokenBySources = (tokens, inputSources) => {
  const inputSourcesArr = Object.keys(inputSources);
  let unMatchedSources = inputSourcesArr;
  // loop each token
  const matchedTokens = tokens.filter((token) => {
    // loop each source of token
    if(!token.node.sources) {
      return false;
    }
    const matchedSources = token.node.sources.filter((source) => {
      // loop each input source
      const isMatched = inputSourcesArr.some((inputSource) => {
        return inputSource.toLowerCase() === source.toLowerCase()
      });
      if(isMatched) {
        // Update unmatched sources
        unMatchedSources = unMatchedSources.filter(uSource => uSource.toLowerCase() !== source.toLowerCase())
        return true;
      } else {
        return false;
      }
    });
    if(matchedSources.length > 0 ) {
      // Update token sources
      token.node.sources = matchedSources;
      return true;
    } else {
      return false;
    }
  });

  return {
    filteredTokens: matchedTokens,
    unMatchedSources
  }
}
