import PropTypes from "prop-types"
import React from "react"

import * as classnames from 'classnames'
import Img from "gatsby-image"

import * as postStyles from '../posts.module.scss';

import { imgSrcType } from '../../../types'

const PostHeader = ({ title, imgSrc, className, author, date, theme }) => (
  <div className={className}>
    <header className={classnames(postStyles.header, postStyles[theme])}>
      <div className={postStyles.title}>
        <h1 className={postStyles.h1}>{title}</h1>
      </div>
      {imgSrc && <Img fluid={imgSrc.childImageSharp.fluid} alt={title} className={postStyles.img} /> }
    </header>
    <div className={postStyles.meta}>
      <p>Posted by {author} on {date}.</p>
    </div>
  </div>
)

PostHeader.propTypes = {
  title: PropTypes.string.isRequired,
  imgSrc: imgSrcType,
  className: PropTypes.string,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  theme: PropTypes.string
}

export default PostHeader
