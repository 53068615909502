import React from "react"
import * as classnames from 'classnames'
import { Link } from "gatsby"

import * as styles from './featuredPost.module.scss';
import * as postStyles from '../posts.module.scss';
import * as helperStyles from '../../helper/helper.module.scss';
import findTokens from "./find-mtg-tokens-for-your-deck.png"

const TokenFinderPost = () => (
  <div className={styles.wrapper}>
    <span className={helperStyles.helperText}>Token Finder Tool</span>
    <Link to={`/token-finder/`} className={classnames(styles.content, styles.finder, postStyles.link)}>
      <h4 className={styles.h4set}>Find tokens for your deck</h4>
      <img className={styles.finderImg} src={findTokens} alt="Find MTG Tokens for your deck" />
      <p>Just paste your decklist to find all the token cards you need for your latest deck.</p>
      <span className={styles.linkText}>Get started &rarr;</span>
    </Link>
  </div>
)

export default TokenFinderPost
