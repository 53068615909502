import * as PropTypes from "prop-types"
import React from "react"
import Helmet from "react-helmet"

import { BackBtn, Breadcrum, SEO } from "../index";
import * as styles from './questionAnswer.module.scss';
import { getDefaultFaqData } from '../../utils/structuredData';

class QuestionAnswer extends React.PureComponent {
  render() {
    const {
      question,
      answer,
      children,
      route,
      formattedDate,
      date,
    } = this.props;
    const breadcrumPages = [{
      text: `FAQ`,
      to: '/faq/',
    },{
      text: question,
      to: route,
      active: true
    }];
    return (
      <>
        <SEO
          title={question}
          description={answer}
          keywords={['FAQ', 'question', 'answer', 'Q&A']}
          pageUrl={route}
        />
        <Helmet>
          <script type="application/ld+json">{getDefaultFaqData({
            route: route, 
            question: question, 
            answer: answer, 
            date: date
          })}
          </script>
        </Helmet>
        <article>
          <Breadcrum pages={breadcrumPages} />
          <header className={styles.question}>
            <span>Q.</span>
            <h1>{question}</h1>
          </header>
          <div className={styles.answer}>
            <span>A.</span>
            <div>
              {children}
              <p className={styles.meta}><em>Answered on {formattedDate} in frequently asked questions.</em></p>
            </div>
          </div>
        </article>
        <BackBtn />
      </>
    )
  }
}

QuestionAnswer.propTypes = {
  route: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  formattedDate: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default QuestionAnswer