import React from "react"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import * as classnames from 'classnames'

import { Colors, Sources, Sets, Artwork, Promos } from '../../index';
import { insertIcons } from '../../../utils/index'
import * as styles from './type.module.scss';
import { tokenTypeType, artworkTokenType } from '../../../types'

class TokenType extends React.PureComponent {
  constructor(props) {
    super(props);
    const { token: { tokens }, featuredToken } = props;
    this.state = {
      featuredToken: featuredToken || (tokens ? tokens[0] : null)
    };
  }

  handleArtworkClick = (index) => {
    const { tokens } = this.props.token;
    this.setState({
      featuredToken: tokens[index]
    })
  }

  render() {
    const { compact, token } = this.props;
    const { 
      name, color,
      power, toughness,
      text,
      types, subTypes,
      sources, tokens
    } = token;
    const { 
      featuredToken: {
        artist,
        imgSrc: {
          childImageSharp
        },
        set,
        promo,
        foil,
        id: featuredTokenId
      }
    } = this.state;
    return (
      <article className={styles.wrapper}>
        <div className={styles.container}>
          {
            childImageSharp && <Img fluid={childImageSharp.fluid} className={classnames(styles.img, {
              [styles.smImg]: compact,
            })}/> 
          }
          <div className={styles.content}>
            <h3>
              <Colors color={color} />
              {name}
            </h3>
            <p>
              {types && types.map((type)=>`${type} `)}
              {subTypes && (
                <>
                  —
                  {subTypes.map((subType)=>` ${subType}`)}
                </>
              )}
              <br />
              {power && `${power} / ${toughness}`}
            </p>
            <div className={styles.text}>
              {text && <p dangerouslySetInnerHTML={insertIcons(text)} />}
            </div>
            <div className={styles.lists}>
              {
                !compact && <Sets set={set} />
              }
              {artist && <p><strong>Illustrated by:</strong> {artist}</p>}
              {(foil || promo) && <p><strong>Promo:</strong><Promos foil={foil} promo={promo}/></p>}
              <Sources sources={sources} />
            </div>
          </div>
        </div>
        {
          !compact && <Artwork tokens={tokens} onClick={this.handleArtworkClick} active={featuredTokenId} />
        }
      </article>
    )
  }
}

TokenType.propTypes = {
  compact: PropTypes.bool.isRequired,
  token: PropTypes.shape({
    ...tokenTypeType
  }),
  featuredToken: PropTypes.shape({
    ...artworkTokenType
  })
}

TokenType.defaultProps = {
  compact: false
}

export default TokenType
