import React from "react";
import * as PropTypes from "prop-types"
import * as classnames from "classnames"

import { Link } from "gatsby"
import { Set, Colors } from '../../index'

import * as styles from './filterList.module.scss'
import * as sidebarStyles from '../sidebar.module.scss'

import { baseTokenType } from '../../../types'

const SHOW_LIMIT = 10;

class FilterList extends React.PureComponent {
  constructor(props) {
    super(props);
    const { typeList, setList } = props;
    const filterList = typeList || setList;
    this.state = {
      filteredList: filterList.slice(0, SHOW_LIMIT),
      query: '',
      type: typeList ? 'type' : 'set',
      expanded: false
    };
  }

  onInputChange(event) {
    const query = event.target.value.toLowerCase();
    this.setState({ query, expanded: true }, () => this.filterList());
  }

  showMore = () => {
    const { typeList, setList } = this.props;
    this.setState({
      expanded: true,
      filteredList: typeList || setList
    })
  }

  onClearClick() {
    const { typeList, setList } = this.props;
    const filterList = typeList || setList;
    this.inputField.value = '';
    this.setState({ 
      query: '',
      expanded: false,
      filteredList: filterList.slice(0, SHOW_LIMIT)
    });
    this.inputField.focus();
  }

  filterList() {
    const { query, type } = this.state;
    const list = type === 'set' ? this.props.setList : this.props.typeList;

    const filterList = list.filter((edge) => {
      return edge.node.name.toLowerCase().indexOf(query) !== -1;
    });
    this.setState({ filteredList: filterList });
  }

  renderForSet() {
    const { filteredList } = this.state;
    if(!filteredList || filteredList.length < 1)  {
      return (
        <li>No sets found.</li>
      )
    }
    return filteredList.map(edge => {
      const { id, name, tokens } = edge.node;
      return (
        <li key={id}>
          <Link to={`/tokens/${id}/`} className={styles.link}>
            <Set id={id} name={name} className={styles.set} />
            {name}
            {` `}
            <span className={styles.fade}>{` (${tokens.length})`}</span>
          </Link>
        </li>
      )
    })
  }

  renderForType() {
    const { filteredList } = this.state;
    if(!filteredList || filteredList.length < 1)  {
      return (
        <li>No tokens found.</li>
      )
    }
    return filteredList.map(edge => {
      const { id, name, power, toughness, color } = edge.node;

      return (
        <li key={id}>
          <Link to={`/token-list/${id}/`} className={styles.link}>
            <Colors size="xs" color={color} className={styles.color} />
            {name}
            {power && (<span className={styles.fade}>{` (${power}/${toughness})`}</span>)}
          </Link>
        </li>
      )
    })
  }
  render() {
    const { 
      title, pageUrl, typeList,
      className, searchText, setList
    } = this.props;
    const { query, expanded } = this.state;
    return (
      <div className={classnames(styles.section, className)}>
        <h4>
          <Link className={sidebarStyles.title} to={pageUrl}>{`${title} `}<span className={sidebarStyles.arrow}>&rsaquo;</span></Link>
        </h4>
        <div className={styles.filter}>
          <span className={classnames(sidebarStyles.search, styles.search)}>&#9906;</span>
          <input
            type="text"
            placeholder={`Search for a ${searchText}`}
            className={styles.filterText}
            onChange={(e) => this.onInputChange(e)}
            ref={el => this.inputField = el}
          />
          <button onClick={() => this.onClearClick()} className={classnames(styles.clear, {
            [styles.visible]: query.length > 0
          })}>&#10005;</button>
        </div>
        <ul className={classnames(sidebarStyles.list, styles.list)}>
          {typeList && this.renderForType() }
          {setList && this.renderForSet() }
          {!expanded && (
            <li>
              <button onClick={this.showMore} className={styles.showMore}>+ Show all</button>
            </li>
          ) }
        </ul>
      </div>
    );
  }
}

FilterList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  pageUrl: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
  typeList: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      ...baseTokenType,
    }).isRequired
  })),
  setList: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      tokens: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
      })).isRequired
    }).isRequired
  })),
}

export default FilterList;
