// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-posts-page-js": () => import("/opt/build/repo/src/templates/posts-page.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-most-common-mtg-tokens-js": () => import("/opt/build/repo/src/templates/most-common-mtg-tokens.js" /* webpackChunkName: "component---src-templates-most-common-mtg-tokens-js" */),
  "component---src-templates-faq-most-popular-js": () => import("/opt/build/repo/src/templates/faq-most-popular.js" /* webpackChunkName: "component---src-templates-faq-most-popular-js" */),
  "component---src-templates-faq-multicolor-js": () => import("/opt/build/repo/src/templates/faq-multicolor.js" /* webpackChunkName: "component---src-templates-faq-multicolor-js" */),
  "component---src-templates-token-list-page-js": () => import("/opt/build/repo/src/templates/token-list-page.js" /* webpackChunkName: "component---src-templates-token-list-page-js" */),
  "component---src-templates-tokens-page-js": () => import("/opt/build/repo/src/templates/tokens-page.js" /* webpackChunkName: "component---src-templates-tokens-page-js" */),
  "component---src-templates-faq-page-js": () => import("/opt/build/repo/src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-set-page-js": () => import("/opt/build/repo/src/templates/set-page.js" /* webpackChunkName: "component---src-templates-set-page-js" */),
  "component---src-templates-token-page-js": () => import("/opt/build/repo/src/templates/token-page.js" /* webpackChunkName: "component---src-templates-token-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-complete-token-collection-index-js": () => import("/opt/build/repo/src/pages/complete-token-collection/index.js" /* webpackChunkName: "component---src-pages-complete-token-collection-index-js" */),
  "component---src-pages-evolution-of-magic-token-card-frame-design-index-js": () => import("/opt/build/repo/src/pages/evolution-of-magic-token-card-frame-design/index.js" /* webpackChunkName: "component---src-pages-evolution-of-magic-token-card-frame-design-index-js" */),
  "component---src-pages-faq-how-many-tokens-are-there-in-magic-index-js": () => import("/opt/build/repo/src/pages/faq/how-many-tokens-are-there-in-magic/index.js" /* webpackChunkName: "component---src-pages-faq-how-many-tokens-are-there-in-magic-index-js" */),
  "component---src-pages-faq-index-js": () => import("/opt/build/repo/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-faq-legendary-magic-tokens-index-js": () => import("/opt/build/repo/src/pages/faq/legendary-magic-tokens/index.js" /* webpackChunkName: "component---src-pages-faq-legendary-magic-tokens-index-js" */),
  "component---src-pages-foil-tokens-of-magic-the-gathering-index-js": () => import("/opt/build/repo/src/pages/foil-tokens-of-magic-the-gathering/index.js" /* webpackChunkName: "component---src-pages-foil-tokens-of-magic-the-gathering-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-links-index-js": () => import("/opt/build/repo/src/pages/links/index.js" /* webpackChunkName: "component---src-pages-links-index-js" */),
  "component---src-pages-mtg-missing-tokens-index-js": () => import("/opt/build/repo/src/pages/mtg-missing-tokens/index.js" /* webpackChunkName: "component---src-pages-mtg-missing-tokens-index-js" */),
  "component---src-pages-token-finder-index-js": () => import("/opt/build/repo/src/pages/token-finder/index.js" /* webpackChunkName: "component---src-pages-token-finder-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

