import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'

import * as styles from './promos.module.scss';

const Promos = ({ foil, promo, center }) => {
  if(!foil && !promo) {
    return null;
  }
  return (
    <span className={classnames(styles.promos, {
      [styles.center]: center
    })}>
      {
        promo && <span className={styles.promo}>&#10029; {promo}</span>
      }
      {
        foil && <span className={styles.foil}>&#10040; Foil</span>
      }
    </span>
  )
}

Promos.propTypes = {
  promo: PropTypes.string,
  foil: PropTypes.bool,
  center: PropTypes.bool
}

export default Promos
