import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'

import PaypalLogo from './paypal-logo.png'
import * as styles from './helper.module.scss';

const Paypal = ({ className }) => (
  <div className={classnames(styles.helper, className, styles.paypal)}>
    <span className={styles.helperText}>Donate</span>
    <div className={styles.content}>
      <div>
        <h4>Help support MTG.onl Tokens!</h4>
        <p>Donations help us to pay hosting costs, maintain token data &amp; create original content.<sup>1</sup></p>
      </div>
      <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
        <input type="hidden" name="cmd" value="_s-xclick" />
        <input type="hidden" name="hosted_button_id" value="WLEFEU6BTV39E" />
        <button type="submit" className={styles.paypalBtn} title="PayPal - The safer, easier way to pay online!">
          <img src={PaypalLogo} alt="Donate with PayPal button" />
          Donate with PayPal
        </button>
        <img alt="" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
      </form>
    </div>
  </div>
)

Paypal.propTypes = {
  className: PropTypes.string
}

export default Paypal
