import React from "react";
import { Link } from "gatsby"

import * as styles from './pageLinks.module.scss'
import { pageLinkType } from '../../types'

class PageLinks extends React.PureComponent {
  render() {
    const { prev, next, prevName, nextName } = this.props;
    return (
      <nav className={styles.pageLinks}>
        {prev ? <Link to={prev}>&larr; {` ${prevName}`}</Link> : <span className={styles.disabled}>&larr; Previous</span>}
        {next ? <Link to={next}>{`${nextName} `} &rarr;</Link> : <span className={styles.disabled}>Next &rarr;</span>}
      </nav>
    );
  }
}

PageLinks.propTypes = {
  ...pageLinkType
}

export default PageLinks;
