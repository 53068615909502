import React from "react"
import * as PropTypes from "prop-types"
import Img from "gatsby-image"
import * as classnames from 'classnames'

import { Set } from '../../index'
import * as styles from './artwork.module.scss';
import { artworkTokenType } from '../../../types'

class Artwork extends React.PureComponent {
  render() {
    const { tokens, onClick, active } = this.props;
    if(!tokens || tokens.length < 2) {
      return null;
    }
    return (
      <footer className={styles.wrapper}>
        <strong className={styles.label}>Printed {tokens.length} times:</strong>
        <div className={styles.scroller}>
          <ul className={styles.list}>
            {
              tokens.map((token, index) => {
                const {
                  id,
                  set,
                  imgSrc
                } = token;
                return (
                  <li key={id} className={classnames(styles.item)}>
                    <div role="button" className={classnames(styles.btn, {
                      [styles.active]: active === id
                    })} type="button" onClick={() => onClick(index)}>
                      <Img fluid={imgSrc.childImageSharp.fluid} className={styles.img} />
                      <p className={styles.overflowText}>
                        <Set id={set.id} name={set.name} size="sm" />
                        {set.name}
                      </p>
                    </div>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </footer>
    )
  }
}

Artwork.propTypes = {
  onClick: PropTypes.func.isRequired,
  active: PropTypes.string,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    ...artworkTokenType
  }))
}

export default Artwork
