import React from "react"
import { Link } from "gatsby"

import * as styles from './footer.module.scss';

const Footer = () => (
  <footer className={styles.container}>
    <div className={styles.terms}>
      <ul>
        <li>
          <Link to="/faq/">FAQ</Link>
        </li>
        <li>
          <Link to="/links/">Links</Link>
        </li>
        <li>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/codefor">Contact / Feedback</a>
        </li>
      </ul>
      <p>The graphical information presented on this site about Magic: The Gathering, including token card images, set and mana symbols, and token card text, is copyright Wizards of the Coast, LLC, a subsidiary of Hasbro, Inc.</p>
      <p>This website is not produced by, endorsed by, supported by, or affiliated with Wizards of the Coast.</p>
      <p>All other content is © 2019 MTG.onl Tokens. This site was created by <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/codefor">@codefor</a> as free resource for the Magic community. You can get in touch via <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/codefor">Twitter</a>.</p>
      <p><sup>1</sup>Donations help us to pay hosting costs, maintain token data &amp; create original content. MTG.onl Tokens is <strong>not</strong> a charity, and donations to us are not tax-deductible.</p>
    </div>
  </footer>
)

export default Footer
