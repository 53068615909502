import React from "react";
import * as PropTypes from "prop-types"
import * as classnames from "classnames"
import { Link } from "gatsby"
import Paginator from "paginator";


import * as styles from './pagination.module.scss'

class Pagination extends React.PureComponent {
  createPageLinks = () => {
    const { page, pages, basePath, total, perPage } = this.props;
    if(!pages || pages < 2) {
      return null
    }
    const paginator = new Paginator(perPage, 5);
    const { 
      current_page, 
      first_page, last_page,
      has_previous_page, has_next_page
    } = paginator.build(total, page);
    
    const linksArray = [];

    const noSlashBasePath = basePath.match(/\/$/) ? basePath.substring(0, basePath.length-1) : basePath;

    if(has_previous_page) {
      (first_page > 1) && linksArray.push(
        <li key={1}>
          <Link to={basePath} className={styles.link}>1</Link>
        </li>
      );
      (first_page > 2) && linksArray.push(
        <li key="prev" className={classnames(styles.listItem, styles.skipped)}>
          ...
        </li>
      )
    }

    for (let i = first_page; i <= last_page; i++) {
      const isActive = (i === current_page);
      const to = i === 1 ? basePath : `${noSlashBasePath}/${i}/`;
      linksArray.push(
        <li key={i} className={classnames(styles.listItem, {
          [styles.active]: isActive
        })}>
          <Link to={to} className={styles.link}>{i}</Link>
        </li>
      )
    }

    if(has_next_page) {
      (last_page < pages -1 ) && linksArray.push(
        <li key="next" className={classnames(styles.listItem, styles.skipped)}>
          ...
        </li>
      );
      (last_page < pages) && linksArray.push(
        <li key={pages}>
          <Link to={`${noSlashBasePath}/${pages}/`} className={styles.link}>{pages}</Link>
        </li>
      );
    }

    return (<ul className={styles.list}>{linksArray}</ul>);
  }
  render() {
    const { prev, next, pages } = this.props;
    if (pages < 2 ) {
      return null
    }
    return (
      <nav className={styles.pagination}>
        {prev ? <Link to={prev}>&larr; Previous page</Link> : <span className={styles.disabledLink}>&larr; Previous page</span>}
        {this.createPageLinks()}
        {next ? <Link to={next}>Next page &rarr;</Link> : <span className={styles.disabledLink}>Next page &rarr;</span>}
      </nav>
    );
  }
}

Pagination.propTypes = {
  page: PropTypes.number,
  pages: PropTypes.number,
  basePath: PropTypes.string,
  prev: PropTypes.string,
  next: PropTypes.string,
  perPage: PropTypes.number
}

Pagination.defaultProps = {
  page: 1
}

export default Pagination;
