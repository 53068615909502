import PropTypes from "prop-types"
import React from "react"
import classnames from 'classnames';

const Color = ({ name, className }) => {
  if(!name) {
    return null;
  }
  const colorclass = `ms-${name.toLowerCase()}`;
  return (
    <i className={classnames(colorclass, 'ms ms-cost', className)}></i>
  )
}

Color.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default Color
