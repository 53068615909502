import PropTypes from "prop-types"
import React from "react"

import { colorTypes } from '../../../types'

const colorToName = (color) => {
  switch (color) {
    case 'W':
      return `White`;
    case 'G':
      return `Green`;
    case 'R':
      return `Red`;
    case 'U':
      return `Blue`
    case 'B':
      return `Black`;
    default:
      return null;
  }
}

export const getColorNames = (color) => {
  if(!color) {
    return null;
  }
  if(color.length > 2) {
    return 'Multicoloured '
  }
  let colorName = '';
  color.forEach((color, index) => {
    if(index > 0) {
      colorName += ` `;
    }
    colorName += colorToName(color)
  })
  colorName += ` `

  return colorName;
}

const ColorNames = (color) => {
  if(!color) {
    return null;
  }
  return (
    <>
      {getColorNames(color)}
    </>
  )
}

ColorNames.propTypes = {
  color: PropTypes.arrayOf(PropTypes.oneOf(colorTypes)),
}

export default ColorNames
