import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'
import { Link } from "gatsby"

import { Set } from '../../index'
import * as styles from './featuredPost.module.scss';
import * as postStyles from '../posts.module.scss';
import * as helperStyles from '../../helper/helper.module.scss'

import { baseSetType } from '../../../types'

const SetPost = ({ id, name, releaseDate, tokens }) => (
  <div className={styles.wrapper}>
    <span className={helperStyles.helperText}>Latest Release</span>
    <Link to={`/tokens/${id}/`} className={classnames(styles.content, styles.set, postStyles.link)}>
      <Set id={id} size="xl" className={styles.setIcon} />
      <h4 className={styles.h4set}>{name}</h4>
      <p>The latest set release on {releaseDate} adding {tokens.length} new tokens!</p>
      <span className={styles.linkText}>View tokens &rarr;</span>
    </Link>
  </div>
)

SetPost.propTypes = {
  ...baseSetType,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string
  }))
}

export default SetPost
