import PropTypes from "prop-types"
import React from "react"

import { colorTypes } from '../../../types'
import { getColorNames } from '../colorNames/colorNames'

export const getTokenName = ({ power, toughness, color, name, types, shortName }) => {
  let colorName = null;
  let cardName = null;
  let type = types;
  if(!color && types && types.includes('Creature')) {
    colorName = 'Colorless '
  }
  if(!type || (types && !types.includes('Creature')) || shortName){
    type = null
  }
  if((types && !types.includes('Emblem') && !shortName) ) {
    cardName = 'Token'
  }
  let fullName = power ? `${power}/${toughness} ` : '';
  fullName += color ? getColorNames(color) : '';
  fullName += colorName ? colorName : '';
  fullName += `${name} `;
  if(type) {
    type.forEach(t => {
      fullName += `${t} `
    })
  };
  fullName += cardName ? cardName : '';

  return fullName;
}

const TokenName = ({ ...args }) => {
  return (
    <>
      {getTokenName(args)}
    </>
  )
}

TokenName.defaultProps = {
  shortName: false
}

TokenName.propTypes = {
  power: PropTypes.string,
  toughness: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.arrayOf(PropTypes.oneOf(colorTypes)),
  types: PropTypes.arrayOf(PropTypes.string),
  shortName: PropTypes.bool.isRequired,
}

export default TokenName
