import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import { TokenTypeList, SetList, PostList } from '../index';
import * as styles from './sidebar.module.scss';

class Sidebar extends React.PureComponent {

  renderTokenFinder() {
    return (
      <div className={styles.section}>
        <h4>
          <Link className={styles.title} to="/token-finder/"><span className={styles.search}>&#9906;</span> Token Finder <span className={styles.arrow}>&rsaquo;</span></Link>
        </h4>
        <p>Find tokens for your deck! Enter your deck list and see the Token cards you'll need.</p>
      </div>
    )
  }

  renderFaq() {
    return (
      <div className={styles.section}>
        <h4>
          <Link className={styles.title} to="/faq/"><span className={styles.info}>&#8505;</span> FAQ <span className={styles.arrow}>&rsaquo;</span></Link>
        </h4>
        <p>Frequently asked questions about MTG tokens.</p>
      </div>
    )
  }

  render() {
    const { onTopClick, sideBarRef } = this.props;
    return (
      <nav ref={el => sideBarRef(el)} className={styles.container}>
        <SetList />
        <TokenTypeList />
        {this.renderTokenFinder()}
        <PostList />
        {this.renderFaq()}
        <button onClick={onTopClick} className={styles.back}><span>&uarr;</span> Back to top</button>
      </nav>
    )
  }
}

Sidebar.propTypes = {
  onTopClick: PropTypes.func.isRequired,
  sideBarRef: PropTypes.func.isRequired
}

export default Sidebar
