import React from "react"
import * as PropTypes from "prop-types"
import * as classnames from 'classnames'
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Set, Promos } from '../../index'
import * as styles from './postTokens.module.scss'
import * as tokenGroupStyles from '../tokensGroup.module.scss'
import { breadcrumType, colorTypes, baseTokenType, artworkTokenType } from '../../../types'

class PostTokens extends React.PureComponent {  
  render() {
    const { tokens, title, breadcrum } = this.props;
    return (
      <section className={tokenGroupStyles.wrapper}>
        {
          title && (
            <header className={tokenGroupStyles.header}>
              <h2>{title}</h2>
            </header>
          )
        }
        <ul className={classnames(tokenGroupStyles.container, styles.container)}>
          {
            tokens.map((token) => {
              const {
                id,
                foil,
                promo,
                imgSrc: {
                  childImageSharp
                },
                set: {
                  name: setName,
                  id: setId
                },
                token: {
                  name,
                  id: tokenId,
                  power,
                  toughness
                }
              } = token.node;
              return (
                <li key={id} className={tokenGroupStyles.token}>
                  <Link
                    to={`/token-list/${tokenId}/`}
                    className={tokenGroupStyles.link}
                    state={{
                      breadcrum: breadcrum,
                      featuredTokenId: id
                    }}
                  >
                    <Img fluid={childImageSharp.fluid} className={tokenGroupStyles.img} />
                    <p>
                      <Set id={setId} name={setName} size="sm" />
                      {name}
                      {power && (
                        <span className={tokenGroupStyles.secondary}>
                          ({`${power}/${toughness}`})
                        </span>
                      )}
                      <Promos foil={foil} promo={promo} center={true} />
                    </p>
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </section>
    )
  }
}

PostTokens.propTypes = {
  color: PropTypes.arrayOf(PropTypes.oneOf(colorTypes)),
  breadcrum: breadcrumType,
  title: PropTypes.string,
  tokens: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.shape({
      ...artworkTokenType,
      token: PropTypes.shape({
        ...baseTokenType
      })
    })
  }))
}

export default PostTokens
