import React from "react";
import * as PropTypes from "prop-types"
import { StaticQuery, graphql, Link } from "gatsby"

import * as sidebarStyles from '../sidebar.module.scss'

class PostList extends React.PureComponent {
  render() {
    const { allPostsJson } = this.props.data;
    return (
      <div className={sidebarStyles.section}>
        <h4>
          <Link className={sidebarStyles.title} to="/posts/">Recent Posts <span className={sidebarStyles.arrow}>&rsaquo;</span></Link>
        </h4>
        <ul className={sidebarStyles.list}>
          {
            allPostsJson.edges.map((post, index) => {
              const { title, route } = post.node;
              return (
                <li key={index}>
                  <Link to={route}>
                    {title}
                  </Link>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavAllPostQuery {
        allPostsJson(
          sort: {fields: [date], order: DESC}
          limit: 4
        ) {
          edges {
            node {
              title
              route
            }
          }
        }
      }
    `}
    render={data => <PostList data={data} {...props} />}
  />
)

PostList.propTypes = {
  data: PropTypes.shape({
    allPostsJson: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          title: PropTypes.string.isRequired,
          route: PropTypes.string.isRequired
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired
}
