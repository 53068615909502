import React from "react";
import * as PropTypes from "prop-types"
import * as classnames from "classnames"

import * as styles from './finderField.module.scss'
import { stringToObj } from '../../../utils/finder'

class FinderField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      textareaActive: false,
      query: '',
      error: false
    };
  }

  placeHolderText = `2 Lingering Souls
    Bitterblossom
    Raise the Alarm
    Raise the Alarm
    Raise the Alarm
    2x Spectral Procession
    1 x Sorin, Solemn Visitor
    3* Intangible Virtue
    4 x Marsh Flats`;

  handleTextAreaFocus() {
    this.setState({ textareaActive: true });
  }

  handleTextAreaBlur() {
    const { query } = this.state;
    if(query === '') {
      this.setState({ textareaActive: false });
    }
  }

  handleTextAreaChange(event) {
    const query = event.target.value.toLowerCase();
    this.setState({ query });
  }

  handleFindClick() {
    const { query } = this.state;
    const { onHandleFormSubmit } = this.props;
    const searchText = query.length ? query : this.placeHolderText;
    const dataObj = stringToObj(searchText);
    const dataFound = Object.getOwnPropertyNames(dataObj).length !== 0;
    this.setState({
      error: !dataFound
    })
    onHandleFormSubmit(dataFound ? dataObj : {});
  }

  render() {
    const { textareaActive, error } = this.state;
    return (
      <div className={styles.container}>
        <h3>Getting started:</h3>
        <p>Enter the cardnames in your deck, one on each line. Use any of the text formats shown below:</p>
        <div className={styles.holder}>
          <textarea
            onFocus={() => this.handleTextAreaFocus()}
            onBlur={() => this.handleTextAreaBlur()}
            onChange={(e) => this.handleTextAreaChange(e)}
            className={styles.textArea}
            autoComplete="Off"
          >
          </textarea>
          { 
            !textareaActive && (
              <span
                className={styles.placeholder}
                dangerouslySetInnerHTML={{
                  __html: this.placeHolderText.replace(/\n/g, "<br />")
                }}
              />
            )
          }
        </div>
        <button
          onClick={() => this.handleFindClick()}
          className={classnames(styles.btn, styles.btnFind)}
        >
          <span className={classnames(styles.search, styles.icon)}>&#9906;</span> Find tokens
        </button>
        {
          error && <span className={styles.error}>Error: Enter at least 1 card name</span>
        }
      </div>
    );
  }
}

FinderField.propTypes = {
  onHandleFormSubmit: PropTypes.func.isRequired
};

export default FinderField;
