import PropTypes from "prop-types"
import React from "react"
import * as classnames from 'classnames'

import * as styles from './helper.module.scss';
import { Paypal } from '../index';

class Helper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  componentDidMount () {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch(error) {
      this.setState({
        hasError: true
      });
    }
  }

  render () {
    const { hasError } = this.state;
    const { fallbackClassName, blocked } = this.props;
    if(hasError || blocked) {
      return (
        <Paypal className={fallbackClassName} />
      )
    }
    const { className, slot } = this.props;
    return (
      <div className={classnames(styles.helper, className)}>
        <span className={styles.helperText}>Advertisement</span>
        <ins className={classnames("adsbygoogle", styles.adsbygoogle)}
          data-ad-client="ca-pub-4942127257586117"
          data-ad-slot={slot}
          // data-ad-format="auto"
          // data-full-width-responsive="true"
          data-adtest="on"
          >
        </ins>
      </div>
    );
  }
}

Helper.defaultProps = {
  blocked: false
}

Helper.propTypes = {
  className: PropTypes.string,
  fallbackClassName: PropTypes.string,
  slot: PropTypes.string.isRequired,
  blocked: PropTypes.bool.isRequired,
}

export default Helper
