const stringToObj = (str) => {
  if(!str) {
    return {};
  }

  let lines = str.split(/\n/);
  const data = {};

  for (let i = 0; i < lines.length; i++) {

    let line = lines[i];
    let num = 1;
    const lineTest = line.toLowerCase();

    // Filter empty lines
    if (! /\S/.test(line) || lineTest.includes('sideboard') || lineTest.includes('deck')) continue;

    // Remove SB:
    line = line.replace('SB:', '');

    // Remove everything after (. Arena formatting
    const arenaCheck = line.indexOf('(');
    if (arenaCheck !== -1) {
      line = line.substring(0, arenaCheck);
    }

    // Trim whitespace
    line = line.trim();

    // Remove sets
    line = line.replace(/\[.*?\]\s?/g, '');

    // ignore commented lines
    if (line.indexOf('/') === 0) continue;

    // Check for number
    if (/^\d./.test(line)) {

      // Get number
      num = parseInt(line.replace(/(^\d+)(.+$)/i, '$1'));

      // Remove number and tidy string
      line = line.replace(num, '').trim().replace(/^([x*]\s)/i, "").trim();
    }

    // Check for duplicant
    data[line] = !data[line] ? num : data[line] + 1;
  }

  return data;
};

export {
  stringToObj
};