import PropTypes from "prop-types"
import React from "react"

import * as styles from './header.module.scss';
import * as helperStyles from '../helper/helper.module.scss';
import { Logo, Helper } from '../index'

const Header = ({ onMenuClick, blocked }) => {
  return (
    <header className={styles.container}>
      <Logo />
      <Helper
        slot="6639102060"
        className={helperStyles.helperTopHero}
        fallbackClassName={helperStyles.headerPaypal}
        blocked={blocked}
      />
      <button onClick={onMenuClick} className={styles.menu}>
        <span className={styles.burger}></span>
        <span className={styles.menuText}>Menu <span className={styles.arrow}>&darr;</span></span>
      </button>
    </header>
  )
}

Header.defaultProps = {
  blocked: false,
}

Header.propTypes = {
  onMenuClick: PropTypes.func.isRequired,
  blocked: PropTypes.bool.isRequired,
}

export default Header
